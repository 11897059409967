import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Seo from '../components/seo';
import '../styles/main.scss';

import Navigation from '../components/Navigation';
import WebsiteFooter from '../components/Footer';

import PageHeader from '../components/PageHeader';

const ServicesIntro = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .first {
    flex-basis: 45%;
    @media (max-width: 768px) {
      margin-bottom: 2em;
    }
  }
  .second {
    flex-basis: 45%;
    width: 100%;
    img {
      border-radius: 4px;
    }
  }
`;

const ServicesContainer = styled.div`
  background: #f7faf8;
  padding: 3em 0;
`;

const Services = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ServicesBox = styled.div`
  flex-basis: 48%;
  @media (max-width: 768px) {
    flex-basis: 100%;
  }
  background: #e1eff6;
  margin-bottom: 2em;
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  h4 {
    font-weight: 800;
    font-size: 1em;
  }
  ul {
    padding: 0 1em;
  }
`;

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "services-header.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesImage: file(relativePath: { eq: "services-image.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <Seo title="Services" />
      <Navigation showLogo background />
      <PageHeader title="Services" data={data} />
      <ServicesIntro className="container">
        <div className="first">
          <p>
            Below is a list of services we provide to various antique and art
            dealers. However, we offer impartial advice and recommendations, so
            please feel free to contact us regarding a tailor-made package for
            you and your business.
          </p>
        </div>
        <div className="second">
          <Img
            fluid={data.servicesImage.childImageSharp.fluid}
            alt="Services"
            style={{ height: '400px' }}
          />
        </div>
      </ServicesIntro>
      <ServicesContainer>
        <Services className="container">
          <ServicesBox>
            <h4>Bespoke Website Design and Development</h4>
            <ul>
              <li>Bespoke responsive design which is creative & unique.</li>
              <li>
                Search Engine Optimisation (SEO) strategy and optimisation.
              </li>
              <li>Engaging copywriting (if required).</li>
              <li>Easy to use CMS with full control for you the dealer.</li>
              <li>In-house development team, we do not outsource our builds to third parties.</li>
              <li>Full in person training and continued support.</li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Hosting and Maintenance</h4>
            <ul>
              <li>
                Domains, hosting and maintaining your website can be costly and
                confusing. We offer a competitive pricing structure for dealers
                based on their site usage and needs.
              </li>
              <li>
                We offer a full management service for dealers who are looking
                for hosting and domain packages, whilst allowing the dealer to
                remain the rightful owner of such an important business asset.
              </li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Social Media Management and Strategy</h4>
            <ul>
              <li>
                Management of Instagram, Facebook, Pinterest, Twitter and
                LinkedIn profiles to provide professional content for our
                dealers.
              </li>
              <li>
                Whether you are starting from scratch or have an established
                following we can assist in managing and growing your Social
                Media presence.
              </li>
              <li>
                Social Media Adverts, Management and Strategy across all Social
                Media platforms.
              </li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Newsletter and Digital Marketing Services</h4>
            <ul>
              <li>
                Design and build of your newsletters to send to your customer
                base.
              </li>
              <li>
                Management of your secure and GDPR compliant mailing list of any
                size.
              </li>
              <li>Weekly, monthly or annual send outs to your subscribers.</li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Photography & Videography</h4>
            <ul>
              <li>
                Photography, including product, organic and showroom shots.
              </li>
              <li>Photoshop and editing.</li>
              <li>Videography of any format including Drone footage.</li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Branding & Graphic Design</h4>
            <ul>
              <li>Logo design.</li>
              <li>Social Media branding.</li>
              <li>Newsletter branding.</li>
              <li>Graphic Design of multi-purpose content.</li>
              <li>Print and Digital Graphic Design.</li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Stock and Marketplace Management</h4>
            <ul>
              <li>
                Uploading stock onto various marketplaces can be time consuming
                and tiresome. We can help!
              </li>
              <li>
                Managing Stock and stock rotation is the key to success on
                various marketplaces.
              </li>
              <li>
                We offer full management on various platforms such as Trade Association marketplaces, Love Antiques, Selling Antiques, 1stDibs etc.
              </li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Online PR</h4>
            <ul>
              <li>
                Bespoke campaigns to create growth and exposure for your pieces,
                news and events.
              </li>
              <li>
                Offer exposure through our journalistic and brand partners.
              </li>
              <li>Directing your online presence at your targeted audience.</li>
              <li>
                With our relationships and contacts, we stand out and deliver
                results for the antique industry.
              </li>
              <li>
                For us, digital PR is more than link building - although that is
                highly important for Search Engine algorithms and getting
                traffic to your site - it's about getting your campaigns and
                content in front of the right audiences who are interested in
                your pieces.
              </li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Consultancy</h4>
            <ul>
              <li>Non-committal website and online presence audits.</li>
              <li>
                Free help and advice to the entire Art & Antiques Trade, feel
                free to contact us with any questions.
              </li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Event Support and Management</h4>
            <ul>
              <li>On Site event support at Trade Exhibitions and Fairs.</li>
              <li>
                An experienced team who have managed international events.
              </li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>SEO</h4>
            <ul>
              <li>SEO optimisation for your current website.</li>
              <li>Content audits.</li>
              <li>Back-linking.</li>
            </ul>
          </ServicesBox>
          <ServicesBox>
            <h4>Google Ad-Words</h4>
            <ul>
              <li>
                Google Advert management to drive targeted traffic to your
                website, maximise online sales and generate business.
              </li>
              <li>
                Promote services and product across Google and other sites.
              </li>
              <li>
                Get your website and Ads in front of your target clientele.
              </li>
            </ul>
          </ServicesBox>
        </Services>
      </ServicesContainer>
      <WebsiteFooter />
    </>
  );
};

export default ServicesPage;
